











































































import { Observer } from 'mobx-vue'
import { Component, Provide, Ref, Vue, Watch } from 'vue-property-decorator'
import { RctDaoViewModel } from '@/modules/recruitment/daos/viewmodels/rct-dao-viewmodel'

@Observer
@Component({
  components: {
    'rule-board': () => import('@/modules/community-organize/components/highlight-boards/rule-board.vue'),
    'faq-board': () => import('@/modules/community-organize/components/highlight-boards/faq-board.vue'),
    'rct-dao-profile-section': () => import('@/modules/recruitment/daos/components/rct-dao-profile-section.vue'),
    'rct-dao-manage-board': () => import('@/modules/recruitment/daos/components/rct-dao-manage-board.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class CommunityDetail extends Vue {
  @Provide() rctVm = new RctDaoViewModel()
  @Ref('activity-menu') activityMenu: any

  focusActivityMenu(entry, unobserve): void {
    if (!entry.isIntersecting) {
      this.activityMenu?.blur()
    }
  }

  @Watch('$route.path', { immediate: true }) pathChanged(path) {
    if (path) {
      const elementPaths = path.split('/')
      this.rctVm.changeTab(elementPaths.slice(-1))
    }
  }
}
