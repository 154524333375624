import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { getFollowStatsNumberAfterSync, getPostStatsNumberAfterSync } from '@/helper/utils'
import { CommunityModel } from '@/models/community-model'
import { ProfileModel } from '@/models/profile-model'
import { recruitmentPostController } from '@/modules/recruitment/dialogs/recruitment-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { apiService } from '@/services/api-services'
import { dispatcher, FollowingProfile, FollowTypeEnum, PostActionModel } from '@/stores/dispatcher'
import { PostStore } from '@/stores/post-store'
import { PostsStore } from '@/stores/posts-store'
import { walletStore } from '@/stores/wallet-store'
import { Log } from '@algo-builder/runtime/build/interpreter/opcode-list'
import { map } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction, toJS, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { recruitmentStore } from '@/stores/recruitment-store'

const Tabs = [
  { title: 'All post', queryParam: 'all-post' },
  { title: 'Find My team', queryParam: 'all-post' },
  { title: 'Find Job', queryParam: 'all-post' },
  { title: 'Find Hobby Buddy', queryParam: 'all-post' },
]

export class RctDaoViewModel {
  @observable postsStore?: PostsStore
  @observable postListFilters = ['Latest', 'Top']
  @observable selectedPostFilter = this.postListFilters[0]
  @observable communityProfile?: CommunityModel = undefined
  @observable communityId
  @observable contributors?: ProfileModel[] = []

  @observable postFetching = false

  @observable loadingMore = false
  @observable loadMoreState = { _start: 0, _limit: 9 }
  @observable loadingData = false
  @observable isImageDAOLoading = false
  @observable selectedRctTab = ''

  @computed get postStores() {
    return this.postsStore?.posts
  }

  constructor() {
    this.communityId = process.env.VUE_APP_RECRUITMENT_ID as any
    this.loadData()
  }

  @action changeTab(elementPath) {
    const tab = this.menu.find((item) => item.path.includes(elementPath))
    this.selectedRctTab = tab?.path || ''
  }

  @asyncAction *loadData() {
    recruitmentStore.loadRecruitment()
    yield when(() => recruitmentStore.recruitmentLoaded)
    this.communityProfile = recruitmentStore.recruitmentDao
    this.contributors = recruitmentStore.contributors
  }

  @asyncAction *onCoverImageDAOChange(event) {
    try {
      this.isImageDAOLoading = true
      const file: File = event.target?.files[0]
      if (!file) return
      const fileS3 = yield apiService.daos.upload(file)
      const daoUpdated = yield apiService.daos.updateCoverDAO({
        daoId: this.communityId,
        avatar: fileS3[0]?.id,
      })
      if (this.communityProfile) {
        this.communityProfile.avatar = daoUpdated.avatar
      }
    } catch (error) {
    } finally {
      this.isImageDAOLoading = false
    }
  }

  @computed get totalDaoPosts() {
    return recruitmentStore.totalDaoPosts
  }

  @action.bound get4LastCharacter(profile: ProfileModel) {
    const profileWalletAddress = profile?.walletAddress
    if (profileWalletAddress) return profileWalletAddress.substr(profileWalletAddress.length - 4)
    else return ''
  }

  @computed get avatarUrl() {
    return this.communityProfile?.avatar?.url
  }

  @computed get rules() {
    return this.communityProfile?.rule?.list || []
  }
  @computed get faqs() {
    return this.communityProfile?.faq?.list || []
  }

  @computed get owner() {
    return this.communityProfile?.profile
  }

  @computed get isOwnerDao() {
    return this.owner && this.owner._id === walletStore.userProfile?._id
  }

  @computed get isContributorDao() {
    return map(this.contributors, 'id').includes(walletStore.userProfile?._id)
  }
  @computed get isAdmin() {
    return this.isOwnerDao || this.isContributorDao
  }

  @computed get menu() {
    return [
      { label: 'Browse all', icon: 'mdi-bullseye-arrow', path: '/recruitment/posts' },
      { label: 'Posted jobs', icon: 'mdi-post', path: '/recruitment/posted-jobs' },
      { label: 'My apply', icon: 'mdi-text-box-outline', path: '/recruitment/my-apply' },
    ]
  }
}
