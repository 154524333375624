var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex full-height"},[_c('div',{staticClass:"main-container-wrapper d-flex flex-column overflow-hidden fill-width"},[_c('div',{staticClass:"text-head2 mb-6"},[_vm._v("Find your Niche")]),_c('rct-dao-profile-section'),_c('div',{staticClass:"d-flex main-container-layout mt-6"},[_c('div',{staticClass:"manage-board"},[_c('rct-dao-manage-board')],1),_c('div',{staticClass:"fill-width overflow-hidden"},[_c('div',[(!_vm.$vuetify.breakpoint.lgAndUp)?_c('observer',{on:{"on-change":_vm.focusActivityMenu}},[_c('v-select',{ref:"activity-menu",staticClass:"c-select-custom",attrs:{"items":_vm.rctVm.menu,"item-text":"state","item-value":"path","label":"Select","persistent-hint":"","return-object":"","single-line":"","solo":"","menu-props":{
                rounded: 'b-lg',
                contentClass: ("activity-menu menu-no-radius custom-menu " + (_vm.$vuetify.breakpoint.smOnly ? 'fill-width' : '')),
                offsetY: true,
                zIndex: 2,
              },"item-color":"white","append-icon":"mdi-chevron-down","hide-details":"","height":"50"},on:{"change":function($event){return _vm.$router.push($event.path)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"tab-item d-flex align-center"},[_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.icon))])],1),_c('div',{staticClass:"tab-title ml-2 fill-width"},[_vm._v(_vm._s(item.label))])])]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"tab-item d-flex align-center"},[_c('div',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(item.icon))])],1),_c('div',{staticClass:"tab-title ml-2"},[_vm._v(_vm._s(item.label))])])]}}],null,false,3684356568),model:{value:(_vm.rctVm.selectedRctTab),callback:function ($$v) {_vm.$set(_vm.rctVm, "selectedRctTab", $$v)},expression:"rctVm.selectedRctTab"}})],1):_vm._e()],1),_c('router-view')],1)])],1),(_vm.$vuetify.breakpoint.width >= 1280)?_c('div',{staticClass:"veritcal-divider"}):_vm._e(),(!_vm.$vuetify.breakpoint.smOnly)?_c('div',{staticClass:"right-board-wrapper d-flex justify-end"},[_c('div',{staticClass:"board-section d-flex flex-column"},[_c('rule-board',{attrs:{"vm":_vm.rctVm}}),_c('faq-board',{attrs:{"vm":_vm.rctVm}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }